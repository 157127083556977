#root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f0ebf8;
}

html {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-color: #f0ebf8;
}

body {
  background-color: #f0ebf8;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

* {
  font-family: Roboto, 'Inter', sans-serif;
  outline: none;
}
